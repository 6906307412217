import React from "react";
import { MdOutlineSecurity } from "react-icons/md";

const ServiceCard = ({ icon, title, description }) => {
  return (
    <>
      <div className="w-full flex flex-col space-y-6 bg-[#EEF9FF] px-5 py-10 md:w-[47%] xl:w-[31%] dark:bg-[#1A2227]">
        <div className="mx-auto p-4 rotate-45 bg-primary text-white">
          {icon}
        </div>

        <div className="flex flex-col space-y-4">
          <p className="text-xl nunito font-bold">{title}</p>
          <p className="opacity-85">{description}</p>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
