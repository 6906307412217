import React, { useEffect, useState } from "react";
import Logo from "../Assets/Images/logo.png";
import LogoWhite from "../Assets/Images/logo-light.png";
import { NavLink } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import { BsFillSunFill } from "react-icons/bs";
import { FaMoon } from "react-icons/fa";

const Navbar = ({ setIsDark, isDark }) => {
  const [navbarBg, setNavbarBg] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 80) {
      setNavbarBg(true);
    } else {
      setNavbarBg(false);
    }
  };

  const toggleMode = () => {
    setIsDark(!isDark);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <nav
        className={`fixed w-full shadow-xl h-20 flex flex-col justify-center z-50
             ${
               navbarBg
                 ? `bg-white dark:bg-black dark:text-white`
                 : ` text-white border-b border-white border-opacity-50 bg-transparent`
             }
          `}
      >
        <div className="flex justify-between items-center container mx-auto px-4 max-w-[1400px]">
          <img
            src={LogoWhite}
            alt=""
            className={`w-24 ${navbarBg && "hidden"}`}
          />
          <img
            src={isDark ? LogoWhite : Logo}
            alt=""
            className={`w-24 ${!navbarBg && "hidden"}`}
          />
          <div className="hidden space-x-10 lg:flex">
            <a href={`#home`} className={`flex flex-col space-y-1`}>
              <p className="text-lg ">Home</p>
            </a>

            <a href={`#about`} className={`flex flex-col space-y-1`}>
              <p className="text-lg ">About</p>
            </a>

            <a href={`#product`} className={`flex flex-col space-y-1`}>
              <p className="text-lg">Product</p>
            </a>

            <a href={`#service`} className={`flex flex-col space-y-1`}>
              <p className="text-lg">Services</p>
            </a>

            <a href={`#contact`} className={`flex flex-col space-y-1`}>
              <p className="text-lg">Contact</p>
            </a>
            <button onClick={toggleMode}>
              {isDark ? (
                <BsFillSunFill className="text-2xl text-[gold]" />
              ) : (
                <FaMoon className="text-2xl" />
              )}
            </button>
          </div>
          <div className="flex items-center space-x-5 lg:hidden">
            <button onClick={toggleMode}>
              {isDark ? (
                <BsFillSunFill className="text-2xl text-[gold]" />
              ) : (
                <FaMoon className="text-2xl" />
              )}
            </button>
            <button
              className=""
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <IoMenu
                className={`text-2xl ${
                  navbarBg ? `text-black dark:text-white` : ` text-white`
                }`}
              />
            </button>
          </div>
        </div>
      </nav>
      {isOpen === true && <MobileNavbar />}
    </>
  );
};

export default Navbar;

export const MobileNavbar = () => {
  return (
    <>
      <div className="w-full bg-white dark:bg-black dark:text-white shadow-xl top-20 fixed flex flex-col items-center space-y-4 justify-center py-6 border-b-xl z-50">
        <a
          href={`#home`}
          className={`flex items-center space-x-4 nunito font-medium active:text-primary`}
        >
          <p className="text-lg ">Home</p>
        </a>

        <a
          href={`#about`}
          className={`flex items-center space-x-4 nunito font-medium active:text-primary`}
        >
          <p className="text-lg ">About</p>
        </a>

        <a
          href={`#product`}
          className={`flex items-center space-x-4 nunito font-medium active:text-primary`}
        >
          <p className="text-lg">Products</p>
        </a>

        <a
          href={`#service`}
          className={`flex items-center space-x-4 nunito font-medium active:text-primary`}
        >
          <p className="text-lg">Services</p>
        </a>

        <a
          href={`#contact`}
          className={`flex items-center space-x-4 nunito font-medium active:text-primary`}
        >
          <p className="text-lg">Contact</p>
        </a>
      </div>
    </>
  );
};
