import {
  FaCubes,
  FaUsersCog,
  FaAward,
  FaPhoneAlt,
  FaWhatsapp,
} from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa6";

export const aboutItems = [
  {
    id: 1,
    title: "SwiftTech",
  },
  {
    id: 2,
    title: "24/7 Technical Support",
  },
  {
    id: 3,
    title: "Professional Staff",
  },
  {
    id: 4,
    title: "Smart Solve",
  },
];

export const chooseUs = [
  {
    title: "Best In Industry",
    description:
      "Our innovative solution sets industry standards and is backed by ongoing research and creative problem-solving.",
    icons: <FaCubes className="text-white text-xl" />,
  },

  {
    title: "Award Winning",
    description:
      "Join the ranks of satisfied clients who have experienced our award-winning solutions and services that will take your business to the next level",
    icons: <FaAward className="text-white text-xl" />,
  },
];

export const chooseUs2 = [
  {
    title: "Professional Staff",
    description:
      "Our professionals leverage industry expertise and cutting-edge technology to deliver innovative, effective solutions that exceed expectations.",
    icons: <FaUsersCog className="text-white text-xl" />,
  },

  {
    title: "24/7 Support",
    description:
      "Count on us for round-the-clock support, with our dedicated team ready to swiftly address any issues and provide efficient, effective solutions.",
    icons: <FaPhoneAlt className="text-white text-xl" />,
  },
];

export const contact = [
  {
    title: "Call to ask any question",
    main: "+234 814 6971 020",
    icons: <FaPhoneAlt className="text-white text-3xl" />,
  },
  {
    title: "Make Enquiries",
    main: "info@revbixs.com",
    icons: <FaEnvelope className="text-white text-3xl" />,
  },
  {
    title: "Send a Message",
    main: "+234 701 5229 727",
    icons: <FaWhatsapp className="text-white text-3xl" />,
  },
];
