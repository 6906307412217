import { FaSearch } from "react-icons/fa";
import { FaAndroid, FaCube } from "react-icons/fa6";
import { MdOutlineSecurity } from "react-icons/md";
import { HiCodeBracket } from "react-icons/hi2";

export const serviceData = [
  {
    title: "Biometric Solutions Development",
    icon: <MdOutlineSecurity className="text-2xl -rotate-45" />,
    description:
      "Our experts develop accurate, user-friendly biometric solutions leveraging cutting-edge technologies to enhance security posture and meet specific needs.",
  },
  {
    title: "Mobile Application Development",
    icon: <FaAndroid className="text-2xl -rotate-45" />,
    description:
      "We develop iOS, Android, Cordova and Xamarin apps, mobile back-ends, provide integration and maintenance.",
  },
  {
    title: "Software Development",
    icon: <HiCodeBracket className="text-2xl -rotate-45" />,
    description:
      "We offer customized software development services for desktop, web, and mobile applications to enhance productivity, streamline operations, and engage customers.",
  },
  {
    title: "System Integration",
    icon: <FaCube className="text-2xl -rotate-45" />,
    description:
      "We Support software system integration with an API-centric architecture. We also helps build APIs / Services in a simple and agile manner to internal and external partners.",
  },
  {
    title: "IT Consultancy",
    icon: <FaSearch className="text-2xl -rotate-45" />,
    description:
      "Our IT consultancy empowers organizations to make informed technology decisions and achieve success through expert guidance on cybersecurity, cloud computing, digital transformation, and more.",
  },
];
