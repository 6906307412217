import React from "react";

const AnimatedBar = ({ style }) => {
  return (
    <>
      <div className="w-36 h-1 bg-primary rounded-xl">
        <div
          className={`animate h-full w-3 bg-white dark:bg-black ml-2 ${
            style && style
          }`}
        ></div>
      </div>
    </>
  );
};

export default AnimatedBar;
