// import ErpLite from "../Assets/Images/revbixErPP.jpg";
// import ErpPro from "../Assets/Images/revbixErpL.jpg";
// import HMS from "../Assets/Images/revbixHms.jpg";
// import KMS from "../Assets/Images/revbixKms.jpg";
// import PPos from "../Assets/Images/revbixPPos.jpg";
// import PMS from "../Assets/Images/revbixPms.jpg";
// import SPOS from "../Assets/Images/revbixSpos.jpg";
// import SMS from "../Assets/Images/revbixSms.jpg";

import ERPL from "../Assets/Images/ERPL.png";
import ERPP from "../Assets/Images/ERPP.png";
import HMS from "../Assets/Images/HMS.png";
import HospMS from "../Assets/Images/HR.jpg";
import PMS from "../Assets/Images/PMS.png";
import PPOS from "../Assets/Images/PPOS.png";
import RPOS from "../Assets/Images/RPOS.png";
import SPOS from "../Assets/Images/SPOS.png";
import VMS from "../Assets/Images/vms.jpeg";

export const productData = [
  {
    id: 1,
    img_path: ERPL,
    title: "Revbixs ERP Lite",
    description:
      "Revbix Pharmacy POS software is designed to streamline sales, manage inventory, and ensure accurate prescription handling for enhanced efficiency and customer service.",
  },
  {
    id: 2,
    img_path: ERPP,
    title: "Revbixs ERP Pro",
    description:
      "Revbix Pharmacy POS software is designed to streamline sales, manage inventory, and ensure accurate prescription handling for enhanced efficiency and customer service. The ERP For your large scale business",
  },
  {
    id: 3,
    img_path: HMS,
    title: "Revbixs Hotel Management Software",
    description:
      "Revbix Hotel Management software, designed to streamline bookings, manage guest check-ins, track room availability, handle billing, and enhance guest satisfaction for a more efficient hotel experience.",
  },
  {
    id: 4,
    img_path: RPOS,
    title: "Revbixs Restaurant POS",
    description:
      "Revbix Restaurant POS software, designed to streamline order processing, manage inventory, and enhance customer service for a smoother dining experience.",
  },
  {
    id: 5,
    img_path: PPOS,
    title: "Revbixs Pharmacy POS",
    description:
      "Pharmacy POS software, designed to streamline sales, manage inventory, and ensure accurate prescription handling for enhanced efficiency and customer service.",
  },
  {
    id: 6,
    img_path: SPOS,
    title: "Revbixs Supermarket POS",
    description:
      "Supermarket POS software, designed to simplify sales transactions, manage inventory, and improve customer service for a more efficient shopping experience.",
  },
  {
    id: 7,
    img_path: PMS,
    title: "Revbix Petrol Station Management Software",
    description:
      "Petrol Station POS Management Software, tailored to streamline fuel sales, track inventory, and enhance operational efficiency for a seamless customer experience.",
  },

  {
    id: 8,
    img_path: VMS,
    title: "Revbixs Visitor Management Software",
    description:
      "Revbix Visitor Management System is crafted to streamline visitor check-ins, enhance security, and provide a seamless experience for all your guests.",
  },
  {
    id: 9,
    img_path: HospMS,
    title: "Revbixs HR Management Software",
    description:
      "Revbix HR Management software, designed to streamline employee management, automate payroll, track performance, manage benefits, and enhance HR operations, all in one platform, ensuring a more efficient and productive workplace.",
  },
];
