import React from "react";

const ChooseUsCard = ({ title, description, icons }) => {
  return (
    <>
      <div className="flex flex-col space-y-3 dark:text-white">
        <div className="bg-primary text-white w-14 h-14 flex justify-center items-center">
          {icons}
        </div>
        <p className="nunito font-bold text-xl lg:text-2xl">{title}</p>
        <p className="opacity-85">{description}</p>
      </div>
    </>
  );
};

export default ChooseUsCard;
